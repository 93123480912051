@media only screen and (max-width: 991px) {
  .layout-container {
    border-top-left-radius: var(--bs-border-radius-2xl) !important;
    border-top-right-radius: var(--bs-border-radius-2xl) !important;
  }
}

@media only screen and (min-width: 992px) {
  .layout-header {
    max-width: 300px !important;
  }
}
