
.header-title{
  font-size: 33.0556px;
  line-height: 110%;
}

.title-login{
  font-size: 56px;
  line-height: 110%;
}

.login-title-forms{
  font-size: 34px;
  line-height: 46px;
}
.login-greeting-forms{
  font-size: 18px;
  line-height: 30px;
}

.login-forgot-password{
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #E2E2E2;
}

.login-signup-forms{
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF;
}

.header{
  background: linear-gradient(90deg, rgba(#8642F4, 0.1), rgba(#101010, 1)), url(../../images/image-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 960px){
  .header{
    background: linear-gradient(rgba(#8642F4, 0.1), rgba(#101010, 1)), url(../../images/image-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .title-login{
    font-size: 24px;
    line-height: 110%;
    }
  .login-title-forms{
    font-size: 24px;
    line-height: 31px;
  }
  .login-greeting-forms{
    font-size: 14px;
    line-height: 18px;
    color: #E2E2E2;
  }
  .login-forgot-password{
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #E2E2E2;
    }
  .login-signup-forms{
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    }
}
