// Override default variables before the import
$primary: #763DD3;
$secondary: #101010;
$body-bg: #101010;
$body-color: #F2F2F2;

$pagination-color: #fff;
$pagination-bg: $secondary;
$pagination-border-color: $secondary;
$pagination-hover-color: $primary;
$pagination-hover-bg: $secondary;
$pagination-hover-border-color: $secondary;

$pagination-active-color: $primary;
$pagination-active-bg: $secondary;
$pagination-active-border-color: $secondary;
$pagination-disabled-border-color: $secondary;
$pagination-disabled-bg: $secondary;
$body-bg: $secondary;
$body-color: #f2f2f2;
$font-family-base: 'DM Sans';

$input-border-radius: 50rem;
$input-bg: rgba(var(--bs-dark-rgb)) !important;
$input-padding-x: 1rem;
$input-padding-y: 1rem;

$btn-border-radius: 50rem;
$btn-padding-y: 1rem;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@import 'react-toastify/dist/ReactToastify.min.css';

:root {
  --toastify-font-family: var(--bs-body-font-family);
  --toastify-color-success: var(--bs-primary);
  --toastify-color-progress-success: var(--bs-primary);
}
